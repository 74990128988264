import { Link } from 'gatsby';
import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import ScrollUpButton from "react-scroll-up-button";
import Layout from "../components/layout";
import Seo from "../components/seo";

import FAQ from '../images/help-faq-icon.svg'
import Support from '../images/help-live-support-icon.png'
import Contact from '../images/help-contact-us-icon.svg'
import Docs from '../images/help-docs-icon.svg'

function HelpCenter() {
    return (
        <Layout>
            <ScrollUpButton ContainerClassName="custom-scroll-button" />
            <Seo title="Help Center" />
            <Container className="py-5">
                <Row className="justify-content-center text-center mb-lg-5 mb-3">
                    <Col lg={12} className="mb-3">
                        <div className="section-title">
                            <div>Help Center</div>
                            <h3>Help Center</h3>
                        </div>
                        <p>Let’s talk. How can we best assist you?</p>
                    </Col>
                </Row>
                
                <div className='py-5 mb-4'>
                    <Row className='text-center'>
                        <Col sm={6} lg={3} className="mb-lg-0 mb-5">
                            <HelpCard icon={FAQ} target="_self" title="FAQ" content="You’ll find answers to the questions we get asked the most" link="/docs/faq/" />
                        </Col>
                        <Col sm={6} lg={3} className="mb-lg-0 mb-5">
                            <HelpCard icon={Support} target="_blank" title="Live Support" content="Our technical team is available 24/7 to resolve your issues" link="https://tawk.to/chat/62c7cd8db0d10b6f3e7b61d0/1g7e7lsdp" />
                        </Col>
                        <Col sm={6} lg={3} className="mb-lg-0 mb-5">
                            <HelpCard icon={Contact} target="_self" title="Contact Us" content="Write to us with any questions, concerns, or feedback" link="/contact" />
                        </Col>
                        <Col sm={6} lg={3} className="mb-lg-0 mb-5">
                            <HelpCard icon={Docs} target="_self" title="Docs" content="Documentation about our structure and team" link="/docs" />
                        </Col>
                    </Row>
                </div>
            </Container>
        </Layout>
    );
}

export default HelpCenter;

const HelpCard = ({icon, title, content, link, target}) => (
    <Link to={link} target={target} className='help-card d-flex flex-column align-items-center'>
        <img src={icon} alt={title} />
        <h4>{title}</h4>
        <p dangerouslySetInnerHTML={{__html: content}} />
    </Link>
)